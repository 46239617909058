@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap");
$montserrat_font: "Montserrat", sans-serif;
.header {
  border-bottom: 3px solid #1f428a;
}
.contact_navbar {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1f428a;
  width: 830px;
  height: 40px;
  border-bottom-left-radius: 23px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  div {
    a {
      padding: 5px;
      text-decoration: none;
      cursor: pointer;
      span {
        font-family: $montserrat_font;
        color: #fff;
      }
      b {
        font-weight: 700;
        color: #fff;
      }
      br {
        display: none;
      }
    }
  }
}

.logo {
  height: 84px;
  width: 168px;
}

.navbar_div {
  margin-top: 60px;
  ul {
    li {
      font-size: 18px;
      font-family: $montserrat_font;
    }
  }
}

.nav_link {
  padding: 0px 12px;

  > a {
    text-transform: uppercase;
  }
}

.nav_toggle_btn {
  margin-top: 70px;
}

.dropdown_menubar {
  background-color: #eff0f1;
  ul {
    padding: 0;
    border: 1px solid #e0e0e0;

    li {
      > a,
      > div {
        border-bottom: 1.5px solid #eff0f1 !important;
        background-color: transparent !important;
        font-size: 16px;
        color: inherit;
        text-decoration: none;

        &:hover {
          border-bottom: 1.5px solid #1f428a !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .contact_navbar {
    width: 903px;
    height: 34px;
    div {
      a {
        font-size: 18px;
      }
    }
  }
  .navbar_div {
    font-size: 18px;
    font-family: $montserrat_font;
  }
}
@media only screen and (max-width: 1200px) {
  .contact_navbar {
    width: 750px;
    height: 40px;
    div {
      a {
        font-size: 16px;
      }
    }
  }
  .nav_link {
    padding: 0px 5px;
  }
}

@media only screen and (max-width: 992px) {
  .contact_navbar {
    width: 400px;
    height: 50px;
    flex-direction: column;
    align-items: flex-end;
  }
  .logo {
    height: 70px;
    width: 140px;
  }
}

@media only screen and (max-width: 660px) {
  .header {
    position: static !important;
  }
  .contact_navbar {
    position: static;
    width: 100%;
    div {
      a {
        margin: 0px 3px;

        span {
          font-size: 14px;
        }
      }
    }
  }
  .logo {
    height: 70px;
    width: 120px;
  }
  .nav_toggle_btn {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 370px) {
  .contact_navbar {
    min-height: 100px;
    div {
      a {
        br {
          display: block;
        }
      }
    }
  }
}
