:root {
  font-family: "Montserrat", sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  padding-top: 123px;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url(../public/angle-small-down.svg) !important;
  background-size: 10px !important;
  background-position:
    calc(100% - 15px) calc(1em + 7px),
    calc(100% - 15px) calc(1em + 7px),
    100% 0 !important;
  background-repeat: no-repeat;
}

.toaster-wrapper123 div:first-child,
.toaster-wrapper123 div:first-child .defaulterrormessage div:first-child div:first-child {
  display: flex !important;
}

.toaster-wrapper123 div:first-child .defaulterrormessage div[role="status"],
.toaster-wrapper123 div:first-child .defaulterrormessage div:first-child,
.toaster-wrapper123 div:first-child .defaulterrormessage div:first-child div:last-child,
.toaster-wrapper123 div:first-child .defaulterrormessage:first-child {
  display: flex !important;
}

.boxshadow {
  box-shadow: 1px 0px 3px 1px rgba(243, 247, 255, 0.92);
}

.mobileshow {
  display: none !important;
}

.mobilescroolbar::-webkit-scrollbar {
  width: 5px;
}

.mobilescroolbar::-webkit-scrollbar-track {
  background: #fff;
}

.mobilescroolbar::-webkit-scrollbar-thumb {
  background: #1f428a;
}

.mobilescroolbar::-webkit-scrollbar-thumb:hover {
  background: #1f428a;
}

@media screen and (max-width: 576px) {
  .mobile567 {
    margin: 15px 0px !important;
    justify-content: flex-start !important;
  }

  .mobilehide {
    display: none !important;
  }

  .mobilereduce {
    padding: 0px !important;
  }

  .mobileshow {
    display: flex !important;
  }

  .mobilemargin15 {
    margin: 15px 0px !important;
  }

  .mobileheightfull {
    height: auto !important;
    overflow: unset !important;
  }
  .removpad23 {
    padding: 0px !important;
  }
}

#searchMainContainer2 {
  padding: 0% 15px 2% 15px !important;
  height: auto !important;
}

.scrollinfinite {
  position: relative !important;
}

@media only screen and (max-width: 660px) {
  body {
    padding-top: 0px;
  }
}
.mobileDisabled {
  display: block !important;
}

.desktopDisabled {
  display: none !important;
}
@media screen and (max-width: 576px) {
  .mobileDisabled {
    display: none !important;
  }
  .desktopDisabled {
    display: block !important;
  }
  .scrollablblview {
    flex-direction: row !important;
    overflow: auto !important;
    flex-wrap: nowrap !important;
  }
  .scrollablblview button {
    font-size: 14px !important;
  }
  .scrollablblview::-webkit-scrollbar {
    display: none !important;
  }
  .scrollablblview::-webkit-scrollbar {
    width: 0 !important;
  }
  .footer-item ul {
    padding-left: 0px !important;
  }
}

.pagination {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.page-link {
  font-size: 16px !important;
  padding: 10px 15px;
  border: none !important;
  color: #1f428a !important;
  font-weight: 600;
}
.page-link:active,
.page-link:focus {
  /* outline: none !important; */
  /* border: none !important; */
  background: transparent !important;
  box-shadow: none !important;
}

.prev-link {
  background: transparent !important;
}

[class="page-item disabled"] {
  display: none;
}

.activePage > a {
  background: transparent !important;
  color: #1f428a !important;
  border: 1px solid #00000050 !important;
  border-radius: 8px;
}

.externalModal {
  padding: 40px;
  width: 482px;
}

.externalModal > .modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.externalModal > .modalBody > h2 {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.externalModal > .modalBody > p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-align: center;
}

.externalModal > .modalAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 36px;
  width: 100%;
}

.modalAction > button {
  width: 100%;
  padding: 16px 0;
  background-color: transparent;
  border-radius: 8px;
  outline: none;
  border: none;
}

.modalAction > .return {
  color: #7c7c7c;
  border: 1px solid #707070;
}
.modalAction > .continue {
  background-color: #1f428a;
  border: 1px solid #1f428a;
  color: #ffffff;
}

@media screen and (max-width: 576px) {
  .externalModal {
    padding: 20px;
    width: 300px;
  }

  .externalModal > .modalBody > h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .externalModal > .modalBody > p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    text-align: center;
  }
  .modalAction > button {
    width: 100%;
    padding: 8px 0;
    background-color: transparent;
    border-radius: 8px;
    outline: none;
    border: none;
  }
}
