@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap');
$montserrat_font: 'Montserrat', sans-serif;
.footer{
    min-height: 450px;
    background-color: #1F428A;
    padding:30px 80px;
    .container{
    ul{
        list-style-type: none;
        li{
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            padding:7px;
            a{
                color:#fff;
                text-decoration: none;
              
                cursor: pointer;
            }

            a:hover{
                text-decoration: underline;
            }
            
            
        }
    }
}

.footer_banner{
    min-height: 120px;
    border-top: 3px  solid #fff;
    border-bottom: 3px  solid #fff;
   padding:25px 0px 10px 0px;
    p:nth-child(1){
        text-align: center;
        color:#fff;
        font-size: 23px;
    }
    p:nth-child(2){
        text-align: center;
        font-family:$montserrat_font;
        color:#fff;
    }

}
.footer_info{
p{
    padding:30px 0px;
    font-size: 14px;
    font-family:$montserrat_font;
    color:#fff;
    a{
        color:#fff;
        cursor: hover;

    }
}
}

.copyright_container{
    p{
        text-align: center;
        font-size: 12px;
        font-family:$montserrat_font;
        color:#fff;
        font-weight: 100;
        a{
            color:#fff;
            text-decoration-color: #e7e4e4;
            cursor: hover;
    
        }
    }
}
}

@media only screen and (max-width: 992px) {
.footer{
    padding:25px
}
.footer_info{
    p{
        text-align: center;
    }
}
}

@media only screen and (max-width: 600px) {
    .footer{
        padding:20px
    }
    .footer_info{
        p{
            text-align: center;
        }
    }
    }