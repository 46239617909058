.dropdownContainer {
  // display: inline-block;
}

.dropdownHeader {
  cursor: pointer;
  background-color: #f5f5f5;
  font-size: 18px;
  text-transform: uppercase;
  padding: 8px 0;
  font-weight: 300;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #f1f1f1;
  border: 1px solid #e0e0e0;
  width: 100%;
}

.menuItem {
  // padding: 10px;
  cursor: pointer;
}

.menuLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 300;
  color: #000;
  padding: 8px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.menuLink:hover {
  background-color: #eaeaea;
}

.submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #f1f1f1;
  border: 1px solid #e0e0e0;
  margin: 5px;
}

.submenuItem {
}

.submenuLink {
  text-decoration: none;
  color: #000;
  font-weight: 300;
  font-size: 16px;
  padding: 8px;
  display: block;
  border-bottom: 1px solid transparent;
}

.submenuLink:hover {
  background-color: #eaeaea;
}
